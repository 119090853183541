import React, { useEffect } from "react";
import "./Template4.css";
// import html2pdf from 'html2pdf.js';

const Template4 = ({ formData }) => {
  const colorObject = formData?.Color?.find((color) => color.templateKey === "template4");
  const FontObject = formData?.Font?.find((Font) => Font.templateKey === "template4");
  const fontfamily4 = FontObject?.fontFamily;
  console.log("fontfam4",fontfamily4);
  const fontColorPrimary = FontObject?.fontColorPrimary;
  console.log("fontPrim4",fontColorPrimary);
  const fontColorSecondary = FontObject?.fontColorSecondary;
  console.log("fontSeco4",fontColorSecondary);

  // Assign primaryColor and secondaryColor
  const primaryColor ="#ffffff" ; // Default to white if not defined
  const secondaryColor = colorObject?.secondaryColor ; // Default to black if not defined

  console.log("primaryColor4", primaryColor);
  console.log("secondaryColor4", secondaryColor);

  useEffect(() => {
    const updateHeight = () => {
      const container = document.querySelector(".template-container");
      const containerWidth = container.offsetWidth;
      const containerHeight = containerWidth * 1.41421; // A4 paper width-to-height ratio
      container.style.minHeight = `${containerHeight}px`;
      
    };

    const onClick =() =>{
      const container = document.querySelector(".template-container");
      container.scrollIntoView(".template-container", {behavior: 'smooth', block: 'start'});

    }
   

    // Call updateHeight initially and add event listener for window resize
    updateHeight();
    window.addEventListener("resize", updateHeight);
    window.addEventListener("onclick", onClick);

    // Cleanup function to remove event listener
    return () => {
  
      window.removeEventListener("resize", updateHeight);
      window.removeEventListener("onclick", onClick);
    };
  }, []);
  return (
    <div
      className="template-container"
      style={{ borderRadius: "0%", backgroundColor: primaryColor || "#fff", fontFamily: fontfamily4 || "Arial, sans-serif", color: fontColorPrimary || "black" }}
    >
      <div
        className="resume-container"
        style={{ maxWidth: "800px", margin: "0 auto", backgroundColor:  primaryColor || "#fff" }}
      >
        {formData.Details &&
          formData?.Details.map((detail, index) => (
            <header key={index} style={{ textAlign: "center", borderBottom: "2px solid #ddd", paddingBottom: "10px", marginBottom: "20px" }}>
              <div className="name-title">
                <h1 style={{ color: fontColorSecondary || "#888", marginBottom: "5px", fontSize: "36px" }}>{detail.fullName}</h1>
                <p style={{ color: "#666", fontSize: "14px" }}>
                  Email: {detail.email} | Phone: {detail.phone && ` | ${detail.phone}`} | {detail.website}
                  {detail.linkedin && ` | ${detail.linkedin}`}
                  {detail.github && ` | ${detail.github}`}
                </p>
              </div>
            </header>
          ))}
        {formData.Details &&
          formData?.Details.map((detail, index) => (
            <div className="summary" key={index}>
              <h2 style={{ fontSize: "24px", marginBottom: "10px", borderBottom: "2px solid #ddd", paddingBottom: "8px" }}>Professional Summary</h2>
              <p dangerouslySetInnerHTML={{ __html: detail.about }} />
              
            </div>
          ))}
  
        {formData.workFlag && (
          <div className="experience">
            <h2 style={{ fontSize: "24px", marginBottom: "10px", borderBottom: "2px solid #ddd", paddingBottom: "8px" }}>Work Experience</h2>
            {formData?.experience &&
              formData?.experience.map((exp, index) => (
                <div key={index} className="job">
                  <h3 style={{ fontSize: "20px" }}>{exp.jobRole}</h3>
                  <h4 style={{ fontSize: "16px", color: fontColorSecondary || "#888", marginBottom: "10px" }}>
                    {exp.companyName} | {exp.fromDate} - {exp.toDate} | {exp.companyLocation}
                  </h4>
                  <p dangerouslySetInnerHTML={{ __html: exp.jobDescription }} />
                </div>
              ))}
          </div>
        )}
  
        {formData.eduFlag && (
          <div className="education">
            <h2 style={{ fontSize: "24px", marginBottom: "10px", borderBottom: "2px solid #ddd", paddingBottom: "8px" }}>Education</h2>
            {formData?.education &&
              formData?.education.map((edu, index) => (
                <div key={index} className="section-content">
                  <h3>{edu.degree}</h3>
                  <h4>
                    {edu.college} - {edu.location} | Graduated: {edu.fromDate} - {edu.toDate}
                  </h4>
                </div>
              ))}
          </div>
        )}
  
        {formData.skillFlag && (
          <div className="skills">
            <h2 style={{ fontSize: "24px", marginBottom: "10px", borderBottom: "2px solid #ddd", paddingBottom: "8px" }}>Skills</h2>
            {formData?.skills &&
              formData?.skills.map((skill, index) => (
                <ul key={index} className="section-content" style={{ listStyleType: "none", marginLeft: "0" }}>
                  <li style={{ marginBottom: "10px", display: "inline-block", marginRight: "15px" }}>
                    <span className="bold-text">{skill.title}</span> - {skill.skillList}
                  </li>
                </ul>
              ))}
          </div>
        )}
  
        {formData.proFlag && (
          <div className="experience">
            <h2 style={{ fontSize: "24px", marginBottom: "10px", borderBottom: "2px solid #ddd", paddingBottom: "8px" }}>Project Developed Or Handled</h2>
            {formData?.projects &&
              formData?.projects.map((prodit, index) => (
                <div key={index} className="section-content">
                  <h3 className="bold-text">
                    {prodit.title} {prodit?.title && prodit?.stack ? "-" : ""} {prodit.stack}
                  </h3>
                  <p dangerouslySetInnerHTML={{ __html: prodit.desc }} />
                </div>
              ))}
          </div>
        )}
  
        {formData.achFlag && (
          <div className="experience">
            <h2 style={{ fontSize: "24px", marginBottom: "10px", borderBottom: "2px solid #ddd", paddingBottom: "8px" }}>Achievements & Awards</h2>
            {formData?.achivawards &&
              formData?.achivawards.map((ach, index) => (
                <div key={index} className="section-content">
                  <p>{ach.achivawards}</p>
                </div>
              ))}
          </div>
        )}
  
        {formData.addFlag && (
          <div className="experience">
            <h2 style={{ fontSize: "24px", marginBottom: "10px", borderBottom: "2px solid #ddd", paddingBottom: "8px" }}>Additional Information</h2>
            {formData?.additionalInfo &&
              formData?.additionalInfo.map((addinfo, index) => (
                <div key={index} className="section-content">
                  <p>{addinfo.addInfo}</p>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
  
};

export default Template4;
